<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col
                v-if="active_role == 'superadmin'"
                cols="12"
                md="12"
              >
                <b-form-group
                  label="Title"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="data.title"
                    placeholder="Title"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="active_role == 'superadmin'"
                cols="12"
                md="12"
              >
                <b-form-group
                  label="Status"
                  label-for="status"
                >
                  <b-form-select
                    id="status"
                    v-model="data.status"
                    :options="options"
                    value-field="value"
                    text-field="text"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
            <h6>Questioner Form</h6>
            <b-row
              v-for="(dataDetail, idx) in data.survey_detail"
              :key="idx"
            >
              <b-col md="10">
                <b-form-group
                  label="Title"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="data.survey_detail[idx].description"
                    placeholder="Title"
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col cols="1" class="mb-50">
                <b-button variant="danger" @click="deleteDetail(idx)">x</b-button>
              </b-col> -->
              <b-col
                lg="2"
                md="3"
                class="mb-50"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="deleteDetail(idx)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>Delete</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row align-h="between">
              <b-col cols="4">
                <b-button
                  variant="primary"
                  @click="addDetail"
                >
                  Add Form Questioner
                </b-button>
              </b-col>
              <b-col
                cols="2"
                offset="6"
              >
                <b-button
                  variant="primary"
                  @click="submit"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BCard, BCardBody,
  BFormGroup, BFormInput, BFormSelect, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        title: null,
        status: 'nonaktif',
        survey_detail: [
          {
            description: null,
          },
        ],
      },
      options: [
        { value: 'aktif', text: 'Aktif' },
        { value: 'nonaktif', text: 'Tidak Aktif' },
      ],
      active_role: null,
    }
  },
  computed: {
    //
  },
  created() {
    // this.checkAuth()
    this.active_role = localStorage.getItem('role')
  },
  methods: {
    submit() {
      this.$store.dispatch('survey/addSurvey', this.data)
        .then(data => {
          if (data.data.status) {
            this.$bvToast.toast('Success', {
              title: 'Add Survey',
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-top-right',
              autoHideDelay: 3000,
            })

            setTimeout(() => {
              this.$router.push({ path: '/questioner' })
            }, 3000)
          }
        })
        .catch(error => {
          console.error(error)
          // Handle the error
        })
    },
    addDetail() {
      const detailData = {
        description: null,
      }
      this.data.survey_detail.push(detailData)
    },
    deleteDetail(index) {
      this.data.survey_detail.splice(index, 1)
    },
  },
}
</script>
